import FontFaceObserver from 'fontfaceobserver';

export default () => {
	const $html = document.getElementsByTagName('html')[0];
	const heading = new FontFaceObserver('domaine-sans-display-medium');
	const headingItalic = new FontFaceObserver(
		'domaine-sans-display-medium-italic'
	);
	const body = new FontFaceObserver('domaine-text-regular');
	const bodyBold = new FontFaceObserver('domaine-text-bold');

	Promise.all([
		heading.load(),
		headingItalic.load(),
		body.load(),
		bodyBold.load(),
	])
		.then(() => $html.classList.add('fonts-loaded'))
		.catch((a, b, c) => $html.classList.add('fonts-failed'));
};
