import '/src/scss/style.scss';

if (import.meta.hot) {
	import.meta.hot.accept();
}

window.log = console.log;

import { loadApp, loadModule } from '@spon/core';

import webfontloader from '@/plugins/webfontloader';
import detectTab from '@/utils/detectTab';
import activeLinks from '@/utils/activeLinks';
import localLinks from '@/utils/localLinks';
import SetVh from '@/globals/SetVh';
import 'lazysizes';

webfontloader();
detectTab();
activeLinks(window.location.pathname);
localLinks();

// load from data-behaviours
const app = loadApp(name => import(`./behaviours/${name}.js`), document.body); // eslint-disable-line

loadModule({
	module: SetVh,
	id: 'set-vh',
	node: document.body,
	keepAlive: true,
});
